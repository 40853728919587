import _ from "lodash";
import React, { useEffect, useState } from "react";
import { cleanLocalSetup, getSubdomain } from "../helpers/Utils";
import { BEHOME_URL } from "../constants/services";

const AuthContext = React.createContext();

const emptyUser = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  profilePictureUrl: null,
};

const emptyState = {
  token: undefined,
  account: undefined,
  accountUuid: undefined,
  user: emptyUser
};

const AuthProvider = (props) => {
  const [state, setState] = useState({
    ...emptyState,
    token: localStorage.getItem("token"),
    account: localStorage.getItem("account_id"),
    accountUuid: localStorage.getItem("account_uuid"),
    user:
      localStorage.getItem("user") != null
        ? JSON.parse(localStorage.getItem("user"))
        : emptyUser,
  });

  //save user info in local storage
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.user));
  }, [state.user]);

  useEffect(() => {
    if (state.user?.paymentMethods && state.user?.paymentMethods.length > 0) {
      let i = 0;
      let found = false;
      while (i < state.user.paymentMethods.length && !found) {
        let pm = state.user.paymentMethods[i];
        if (pm.isDefault) {
          found = true;
          setUserProfile({ ...state.user, paymentMethodDefault: pm });
        }
        i++;
      }
    } else {
      setUserProfile({ ...state.user, paymentMethodDefault: null });
    }
  }, [state.user?.paymentMethods]);

  const error = new Error("Something went wrong");

  const getAuthHeader = () => {
    return {
      "X-BH-IPASS-AUTH-TOKEN": state.token,
    };
  };

  const authFetch = async (input, init) => {
    init = init || {};
    init.headers = {
      ...init.headers,
      ...getAuthHeader(),
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      };
    }
    return fetch(input, init).then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          if (response.status == 401 || response.status == 403) {
            logout(false);
          } 
          throw { ...error, response };
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const authUpload = async (input, init) => {
    init = init || {};
    init.headers = {
      ...init.headers,
      ...getAuthHeader(),
    };
    return fetch(input, init).then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          if (response.status == 401 || response.status == 403) {
            logout(false);
          }
          throw { ...error, response };
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const residentAuthFetch = async (input, init) => {
    init = init || {};
    init.headers = {
      ...init.headers,
      "X-BH-IPASS-RESIDENT-AUTH-TOKEN": state.token,
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      };
    }

    return fetch(input, init).then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          if (response.status == 401 || response.status == 403) {
            logout(false);
          }
          throw { ...error, response };
        }
      },
      (error) => {
        throw error;
      }
    );
  };

  const login = async (data) => {
    console.log("data", data);
    var user = {};
    /*var user = {};
    if (data.user.dateOfBirth){
      var user = {...data.user, dateOfBirth:moment(data.user.dateOfBirth).format("yyyy-MM-DD")};
    }else{
      user = {...data.user}
    }
    
    if (data.user.addresses && data.user.addresses.length>0){
      user = {...user, address: {...data.user.addresses[0]}}
    }*/

    let aId = undefined;
    let aUuid = undefined;
    if (_.has(data.user, "accounts")) {
      let accountFiltered = getAccountId(data.user.accounts);

      if (accountFiltered.length == 0) {
        accountFiltered = data.user.accounts;
      }

      aId = accountFiltered[0].id;
      aUuid = accountFiltered[0].uuid;
    } else {
      aId = data.user.account.id;
      aUuid = data.user.account.uuid;
    }

    setState({
      token: data.token,
      account: aId, //data.user.accounts[0].id,
      accountUuid: aUuid, //data.user.accounts[0].uuid,
      user: { ...data.user },
    });

    localStorage.setItem("account_id", aId); //for requests
    localStorage.setItem("account_uuid", aUuid); //for requests
    localStorage.setItem("token", data.token); //for auth
    localStorage.setItem("token_expires", data.expires);
    console.log("User logged in");
  };

  useEffect(() => {
    let loggedOut = false;
    const tokenExpireTime = localStorage.getItem("token_expires");
    const token = localStorage.getItem("token");
    if (!tokenExpireTime && token) {
      logout(false);
      loggedOut = true;
    } else if (
      tokenExpireTime &&
      new Date(tokenExpireTime - 60000).getTime() < new Date().getTime()
    ) {
      // if less than one minute remaining then logout to avoid race conditions.
      logout(true);
      loggedOut = true;
    }
    if (loggedOut) {
      window.location.href = window.location.origin;
    }
  }, []);

  /**
   *
   */
  const getAccountId = (accounts) => {
    const subdomain = getSubdomain();
    return accounts.filter((a) => a.subdomain == subdomain);
  };

  const setUserProfile = (profile) => {
    setState({
      ...state,
      user: {
        ...state.user,
        ...profile,
      },
    });
  };

  const logout = (expireToken) => {

    if (expireToken) {
      try {
        authFetch(BEHOME_URL + "/signout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(""),
        });
      } catch (e) {
        console.log(e);
      } 
    }

    setState({
      token: null,
      account: null, //data.user.accounts[0].id,
      accountUuid: null, //data.user.accounts[0].uuid,
      user: null,
    });
    localStorage.removeItem("token");
    localStorage.removeItem("token_expires");
    localStorage.removeItem("account_id");
    localStorage.removeItem("account_uuid");
    localStorage.removeItem("user");
    localStorage.removeItem("selected_property");
    localStorage.removeItem("selected_lease");
    localStorage.removeItem("login_referrer");
    cleanLocalSetup();
    console.log("User logged out");
    setState({...emptyState});
  };

  const register = (data) => {
    setState({ ...state, ...data });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        authFetch,
        residentAuthFetch,
        authUpload,
        setUserProfile,
        getAuthHeader
      }}
      {...props}
    ></AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
